<template>
  <v-container fluid>
    <v-toolbar class="accent" dark dense>
      <v-toolbar-title>User GPS Locations</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="options.date"
                  label="Start Date"
                  readonly
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.date"
                @input="updateRange"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="options.user_id"
              :items="Users"
              item-text="name"
              item-value="id"
              label="User"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="options.full_day"
              :items="FullDay"
              item-text="name"
              item-value="value"
              label="User"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-btn @click="selectGpsLocations()" color="primary">Show</v-btn>
          </v-col>
        </v-row>

        <!-- start of map -->
        <v-row>
          <v-col cols="12">
            <map-section
              :gpsLocations="gpsLocations"
              :centerMap="centerMap"
            ></map-section>
          </v-col>
        </v-row>
        <!-- end of map -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MapSection from "../_components/map.vue";

export default {
  components: {
    MapSection,
  },

  data() {
    return {
      loading: false,
      search: null,
      options: {},
      updateRange: null,
      Users: [],
      trueValue: true,
      gpsLocations: [],
      centerMap: {},
      FullDay: [
        {
          name: "Full Day",
          value: true,
        },
        {
          name: "Current",
          value: false,
        },
      ],
    };
  },
  methods: {
    selectGpsLocations() {
      const self = this;
      self.loading = true;
      //   if (this.options.date && ) {
      // api call
      let url = `/gps-locations/?`;
      if (this.options.user_id) {
        url = `/gps-locations/${this.options.user_id}?`;
      }
      if (this.options.date) {
        url += `${url.includes("?") ? "&" : "?"}date=${this.options.date}`;
      }

      url += `${url.includes("?") ? "&" : "?"}full_day=${
        this.options.full_day || false
      }`;

      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.loading = false;
          self.centerMap = res.ResponseData?.centermap || {};
          self.gpsLocations = res.ResponseData?.locations || [];
        })
        .catch((err) => {
          console.log(err, "error");
          this.$refs.snackbar.show(err, "red");
        });
      //  }
      // end
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.Users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },

  created() {
    this.getUsers();
    this.selectGpsLocations();
  },
};
</script>

<style lang="scss" scoped>
</style>