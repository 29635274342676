<template>
  <GmapMap
    :center="center"
    :zoom="10"
    map-type-id="terrain"
    style="width: 1200px; height: 1000px"
    @click="handleMapClick"
  >
    <GmapMarker
      :key="index"
      v-for="(m, index) in gpsLocations"
      :position="m"
      :clickable="true"
      @click="handleMarkerClick(m)"
    >
      <GmapInfoWindow :options="{ maxWidth: 400 }">
        {{
          (m.name + " , " + m.address + " , " + m.created_at) | moment("LLLL")
        }}
      </GmapInfoWindow>

      <!-- Add an anchor tag around the GmapMarker content -->
      <a href="#" @click.prevent="handleMarkerClick(m)">
        <!-- Your marker content goes here -->
        <!-- You can customize the content of the marker, e.g., display a label, etc. -->
        <div
          style="
            width: 300px;
            height: 30px;
            background-color: black;
            border-radius: 50%;
          "
        ></div>
      </a>
    </GmapMarker>
  </GmapMap>
</template>

<script>
import moment from "moment";
export default {
  props: {
    gpsLocations: {
      type: Array,
    },
    centerMap: {
      type: Object,
    },
  },
  watch: {
    centerMap: {
      handler: "setCenter",
      immediate: true,
    },
  },
  data() {
    return {
      edited: null,
      center: {},
    };
  },
  computed: {
    formattedInfo() {
      return `${this.formatDate(this.m.created_at)}`;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    geolocate: function () {
      const self = this;
      navigator.geolocation.getCurrentPosition((position) => {
        const marker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        self.center = marker;
      });
    },
    setCenter(val) {
      this.center = val;
    },
    handleMapClick(event) {
      console.log(event);
      // Get the clicked location from the event object
      const clickedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        name: event.name.lat(),
        latLng: event.latLng.address(),
      };

      // Do something with the clicked location
      console.log("Map clicked:", clickedLocation);

      // You can set the map center to the clicked location if needed
      this.center = clickedLocation;
    },

    handleMarkerClick(marker) {
      console.log("Clicked Marker:", marker);
      alert("User : " + marker.name + " ," + "Address : " + marker.address);
    },
    getPosition(marker) {
      return {
        lat: parseFloat(marker.Latitude),
        lng: parseFloat(marker.Longitude),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>